import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  waivers: [],
};

const slice = createSlice({
  name: 'penalty-waivers',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.waivers || state.waivers.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getWaiversSuccess(state, action) {
      state.isLoading = false;
      state.waivers = action.payload;
      state.isInitializing = false;
    },
  },
});

export const { startLoading, hasError, getWaiversSuccess } = slice.actions;

export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  temporaryCollectors: [],
};

const slice = createSlice({
  name: 'temporary-collectors',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.temporaryCollectors || state.temporaryCollectors.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getTemporaryCollectorsSuccess(state, action) {
      state.isLoading = false;
      state.temporaryCollectors = action.payload;
      state.isInitializing = false;
    },

    createTemporaryCollectorSuccess(state, action) {
      state.isLoading = false;
      state.temporaryCollectors.push({
        id: action.payload.id,
        clusterId: action.payload.clusterId,
        collectorId: action.payload.collectorId,
        validUntilTime: action.payload.validUntilTime,
      });
    },

    updateTemporaryCollectorSuccess(state, action) {
      state.isLoading = false;
      const index = state.temporaryCollectors.findIndex(
        (temporaryCollector) => temporaryCollector.id === action.payload.id
      );
      let updatedTemporaryCollector = state.temporaryCollectors[index];
      updatedTemporaryCollector = {
        ...updatedTemporaryCollector,
        id: action.payload.id,
        clusterId: action.payload.clusterId,
        collectorId: action.payload.collectorId,
        validUntilTime: action.payload.untilDate,
      };
      state.temporaryCollectors[index] = updatedTemporaryCollector;
    },

    deleteTemporaryCollectorSuccess(state, action) {
      state.isLoading = false;
      state.temporaryCollectors = state.temporaryCollectors.filter(
        (temporaryCollector) => temporaryCollector.id !== action.payload
      );
    },
  },
});

export const {
  startLoading,
  hasError,
  getTemporaryCollectorsSuccess,
  createTemporaryCollectorSuccess,
  updateTemporaryCollectorSuccess,
  deleteTemporaryCollectorSuccess,
} = slice.actions;

export default slice.reducer;

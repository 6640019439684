import PropTypes from 'prop-types';

import { Container } from '@mui/material';

MainContainer.propTypes = {
  sx: PropTypes.any,
  children: PropTypes.any,
};

function MainContainer({ children, sx }) {
  return (
    <Container maxWidth="xl" sx={[sx, { pl: 5, pr: 5 }]}>
      {children}
    </Container>
  );
}

export default MainContainer;

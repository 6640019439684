import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  roles: [],
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.roles || state.roles.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
      state.isInitializing = false;
    },

    createRoleSuccess(state, action) {
      state.isLoading = false;
      state.roles.push({
        id: action.payload.id,
        name: action.payload.name,
        entitlements: action.payload.entitlements,
        status: action.payload.isActive ? 'ACTIVE' : 'INACTIVE',
        createTime: new Date().toISOString(),
        updateTime: new Date().toISOString(),
      });
    },

    updateRoleSuccess(state, action) {
      state.isLoading = false;
      const index = state.roles.findIndex((role) => role.id === action.payload.id);
      let updatedRole = state.roles[index];
      updatedRole = {
        ...updatedRole,
        name: action.payload.name,
        entitlements: action.payload.entitlements,
        status: action.payload.isActive ? 'ACTIVE' : 'INACTIVE',
        updateTime: new Date().toISOString(),
      };
      state.roles[index] = updatedRole;
    },

    deleteRoleSuccess(state, action) {
      state.isLoading = false;
      state.roles = state.roles.filter((role) => role.id !== action.payload);
    },

    deleteRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = state.roles.filter((role) => !action.payload.includes(role.id));
    },
  },
});

export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/references/roles');
      dispatch(slice.actions.getRolesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const { createRoleSuccess, updateRoleSuccess, deleteRoleSuccess, deleteRolesSuccess } = slice.actions;

export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  borrowers: [],
  borrower: null,
};

const slice = createSlice({
  name: 'borrowers',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.borrowers || state.borrowers.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getBorrowersSuccess(state, action) {
      state.isLoading = false;
      state.borrowers = action.payload;
      state.isInitializing = false;
    },

    getBorrowerSuccess(state, action) {
      state.isLoading = false;
      state.borrower = action.payload;
      state.isInitializing = false;
    },

    updateBorrowerSuccess(state, action) {
      state.isLoading = false;
      state.borrower = {
        ...state.borrower,
        ...action.payload,
      };
      state.isInitializing = false;
    },
  },
});

export const { startLoading, hasError, getBorrowersSuccess, getBorrowerSuccess, updateBorrowerSuccess } = slice.actions;

export default slice.reducer;

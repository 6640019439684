import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.notifications || state.notifications.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
      state.isInitializing = false;
    },

    readNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = state.notifications.filter((item) => item.id !== action.payload);
      state.isInitializing = false;
    },

    readAllNotificationsSuccess(state) {
      state.isLoading = false;
      state.notifications = [];
      state.isInitializing = false;
    },
  },
});

export const {
  startLoading,
  hasError,
  getNotificationsSuccess,
  readNotificationsSuccess,
  readAllNotificationsSuccess,
} = slice.actions;

export default slice.reducer;

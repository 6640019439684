import axios from '../utils/axios';
import {
  startLoading,
  hasError,
  getBranches,
  createBranchSuccess as appendBranch,
  updateBranchSuccess as updateBranch,
  deleteBranchSuccess as deleteBranch,
  deleteBranchesSuccess as deleteBranches,
} from '../redux/slices/branch';
import { dataToPayload } from '../mappers/branch-mapper';
import { dispatch } from '../redux/store';

export const list = async () => {
  try {
    dispatch(startLoading());
    const response = await axios.get(`/references/branches`);
    dispatch(getBranches(response.data));
  } catch (error) {
    console.log(error);
    dispatch(hasError(error));
    throw error;
  }
};

export const create = async (data) => {
  const payload = dataToPayload(data);
  try {
    const response = await axios.post(`/references/branches`, payload);
    dispatch(appendBranch({ ...payload, id: response.headers.location }));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const update = async (branchId, data) => {
  const payload = dataToPayload(data);
  try {
    await axios.patch(`/references/branches/${branchId}`, payload);
    dispatch(updateBranch({ ...payload, id: branchId }));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteOne = async (branchId) => {
  try {
    await axios.delete(`/references/branches/${branchId}`, {});
    dispatch(deleteBranch(branchId));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteMany = async (branchIds) => {
  try {
    await axios.delete(`/references/branches?${branchIds.map((n) => `ids=${n}`).join('&')}`, {});
    dispatch(deleteBranches(branchIds));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  payments: [],
};

const slice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },
  },
});

export const { startLoading, hasError, getPaymentsSuccess } = slice.actions;

export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  branches: [],
};

const slice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.branches || state.branches.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getBranchesSuccess(state, action) {
      state.isLoading = false;
      state.branches = action.payload;
      state.isInitializing = false;
    },

    createBranchSuccess(state, action) {
      state.isLoading = false;
      state.branches.push({
        id: action.payload.id,
        name: action.payload.name,
        address: action.payload.address,
        contactInformations: action.payload.contactInformations,
        bank: action.payload.bank,
        dashboardApi: action.payload.dashboardApi,
        status: 'ACTIVE',
        createTime: new Date().toISOString(),
        updateTime: new Date().toISOString(),
      });
    },

    updateBranchSuccess(state, action) {
      state.isLoading = false;
      const index = state.branches.findIndex((branch) => branch.id === action.payload.id);
      let updatedBranch = state.branches[index];
      updatedBranch = {
        ...updatedBranch,
        name: action.payload.name,
        address: action.payload.address,
        contactInformations: action.payload.contactInformations,
        bank: action.payload.bank,
        dashboardApi: action.payload.dashboardApi,
        location: action.payload.location,
        updateTime: new Date().toISOString(),
      };
      state.branches[index] = updatedBranch;
    },

    deleteBranchSuccess(state, action) {
      state.isLoading = false;
      state.branches = state.branches.filter((branch) => branch.id !== action.payload);
    },

    deleteBranchesSuccess(state, action) {
      state.isLoading = false;
      state.branches = state.branches.filter((branch) => !action.payload.includes(branch.id));
    },
  },
});

export function getBranches() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/references/branches');
      dispatch(slice.actions.getBranchesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const {
  startLoading,
  hasError,
  getBranchesSuccess,
  createBranchSuccess,
  updateBranchSuccess,
  deleteBranchSuccess,
  deleteBranchesSuccess,
} = slice.actions;

export default slice.reducer;

import PropTypes from 'prop-types';

// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { Radio, RadioGroup, FormHelperText, FormControlLabel, Typography, Box, Stack } from '@mui/material';

// ----------------------------------------------------------------------

const OptionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2.5),
  justifyContent: 'space-between',
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.any]),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
};

export default function RHFRadioGroup({ name, options, getOptionLabel, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other} disabled={other.disabled} onClick={() => null}>
            {other?.objoption ? (
              <Stack width={'100%'} spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
                {options.map((option) => (
                  <OptionStyle
                    key={option.value}
                    sx={{
                      ...(field.value === option.value && {
                        boxShadow: (theme) => theme.customShadows.z20,
                      }),
                    }}
                  >
                    <FormControlLabel
                      value={option.value}
                      control={<Radio disabled={other.disabled} onClick={other.onClick} />}
                      label={
                        <Box sx={{ ml: 1 }}>
                          <Typography variant="subtitle2">{option.title}</Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {option.description}
                          </Typography>
                        </Box>
                      }
                      sx={{ py: 3, flexGrow: 1, mr: 0 }}
                    />
                  </OptionStyle>
                ))}
              </Stack>
            ) : (
              options.map((option, index) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio disabled={other.disabled} />}
                  label={getOptionLabel?.length ? getOptionLabel[index] : option}
                />
              ))
            )}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

import axios from '../utils/axios';
import {
  startLoading,
  hasError,
  getNotificationsSuccess,
  readNotificationsSuccess,
  readAllNotificationsSuccess,
} from '../redux/slices/notification';
import { dispatch } from '../redux/store';

export const list = async () => {
  try {
    dispatch(startLoading());
    const response = await axios.get('/notifications');
    dispatch(getNotificationsSuccess(response.data));
  } catch (error) {
    console.log(error);
    dispatch(hasError());
    throw error;
  }
};

export const read = async (id) => {
  try {
    await axios.patch(`/notifications/${id}`, {});
    dispatch(readNotificationsSuccess(id));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const readAll = async () => {
  try {
    await axios.patch('/notifications/read-all', {});
    dispatch(readAllNotificationsSuccess());
  } catch (error) {
    console.log(error);
    throw error;
  }
};

import axios from '../utils/axios';
import { dataToPayload } from '../mappers/agent-mapper';

export const listAgent = async (branchId) => {
  try {
    const response = await axios.get(`/external/agents?branchId=${branchId || ''}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createAgent = async (refId, data) => {
    try {
      const payload = dataToPayload(data);
      await axios.post(
        `/external/agents?refId=${refId}`,
        payload
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
};

export const updateAgent = async (id, data) => {
    try {
      const payload = dataToPayload(data);
      await axios.patch(`/external/agents/${id}`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
};

export const postAgentPhoto = async (agentId, file, isNew) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      if (isNew) {
        await axios.post(`/external/agents/${agentId}/photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await axios.patch(`/external/agents/${agentId}/photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
};

export const toggleAgentStatus = async (agentId, activateAgent) => {
    try {
      if (activateAgent) {
        await axios.patch(`/external/agents/${agentId}/activate`, {});
      } else {
        await axios.patch(`/external/agents/${agentId}/deactivate`, {});
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
};
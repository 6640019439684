import { format, getTime, formatDistanceToNow } from 'date-fns';

export function fDate(date) {
  try {
    return format(new Date(date), 'dd MMMM yyyy');
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

export function fDateWithFormat(date, dateFormat) {
  try {
    return format(new Date(date), dateFormat);
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

export function fDateTime(date) {
  try {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

export function fTimestamp(date) {
  try {
    return getTime(new Date(date));
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

export function fDateTimeSuffix(date) {
  try {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

export function fTime(date) {
  try {
    return format(new Date(date), 'p');
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

export function fToNow(date) {
  try {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
    });
  } catch(error) {
    console.log(new Date(date))
    console.log(error)
    return "";
  }
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  requests: [],
};

const slice = createSlice({
  name: 'transfer-requests',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.requests || state.requests.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getRequestSuccess(state, action) {
      state.isLoading = false;
      state.requests = action.payload;
      state.isInitializing = false;
    },
  },
});

export const { startLoading, hasError, getRequestSuccess } = slice.actions;

export default slice.reducer;

import jwtDecode from 'jwt-decode';
import axios from './axios';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;
  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  expiredTimer = window.setTimeout(() => {
    console.log('Session Expired!');
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }, timeLeft);
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('accessToken', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    const decodedToken = jwtDecode(token);
    handleTokenExpired(decodedToken.exp);
    return decodedToken;
  }
  localStorage.removeItem('accessToken');
  delete axios.defaults.headers.common.Authorization;
  return null;
};

export { isValidToken, setSession };

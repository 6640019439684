import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  preference: null,
};

const slice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.preference) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getPreferenceSuccess(state, action) {
      state.isLoading = false;
      state.preference = action.payload;
      state.isInitializing = false;
    },

    updatePreferenceSuccess(state, action) {
      state.isLoading = false;
      state.preference = action.payload;
    },
  },
});

export function getPreference() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/preference');
      dispatch(slice.actions.getPreferenceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePreference(data) {
  const payload = {
    notification: { ...data },
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/preference`, payload);
      dispatch(slice.actions.updatePreferenceSuccess({ ...payload }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;

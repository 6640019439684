import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  assessors: [],
};

const slice = createSlice({
  name: 'internal-user-assessors',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.assessors || state.assessors.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getAssessorsSuccess(state, action) {
      state.isLoading = false;
      state.assessors = action.payload;
      state.isInitializing = false;
    },
  },
});

export function getAssessors() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/internal/accounts/assessors');
      dispatch(slice.actions.getAssessorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;

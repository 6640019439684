import { createSlice } from '@reduxjs/toolkit';
import { listAgent } from '../../apis/agent';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  agents: [],
};

const slice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.agents || state.agents.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getAgentsSuccess(state, action) {
      state.isLoading = false;
      state.agents = action.payload;
      state.isInitializing = false;
    },
  },
});

export function getAgents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = await listAgent();
      dispatch(slice.actions.getAgentsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  clusters: [],
  isRootInitializing: false,
  isRootLoading: false,
  rootError: null,
  rootClusters: [],
};

const slice = createSlice({
  name: 'cluster',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.clusters || state.clusters.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getClustersSuccess(state, action) {
      state.isLoading = false;
      state.clusters = action.payload;
      state.isInitializing = false;
    },

    startRootLoading(state) {
      state.isRootLoading = true;
      if (!state.rootClusters || state.rootClusters.length === 0) {
        state.isRootInitializing = true;
      }
    },

    hasRootError(state, action) {
      state.isRootLoading = false;
      state.rootError = action.payload;
      state.isRootInitializing = false;
    },

    getRootClustersSuccess(state, action) {
      state.isRootLoading = false;
      state.rootClusters = action.payload;
      state.isRootInitializing = false;
    },

    createClusterSuccess(state, action) {
      state.isLoading = false;
      state.clusters.push({
        id: action.payload.id,
        name: action.payload.name,
        assignedCollectorId: action.payload.assignedCollectorId,
        assignedCollectorName: action.payload.assignedCollectorName,
      });
    },

    updateClusterSuccess(state, action) {
      state.isLoading = false;
      const index = state.clusters.findIndex((cluster) => cluster.id === action.payload.id);
      let updatedCluster = state.clusters[index];
      updatedCluster = {
        ...updatedCluster,
        id: action.payload.id,
        name: action.payload.name,
        assignedCollectorId: action.payload.assignedCollectorId,
        assignedCollectorName: action.payload.assignedCollectorName,
      };
      state.clusters[index] = updatedCluster;
    },

    deleteClusterSuccess(state, action) {
      state.isLoading = false;
      state.clusters = state.clusters.filter((cluster) => cluster.id !== action.payload);
    },
  },
});

export const {
  startLoading,
  hasError,
  getClustersSuccess,
  startRootLoading,
  hasRootError,
  getRootClustersSuccess,
  createClusterSuccess,
  updateClusterSuccess,
  deleteClusterSuccess,
} = slice.actions;

export default slice.reducer;

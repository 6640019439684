import axios from 'axios';
import { setSession } from './jwt';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    const { response } = err;
    if (response.status === 401) {
      setSession(null);
      window.location = '/admin';
    } else if (response.status === 403) {
      window.location = '/403';
    } else if (response.status === 404) {
      window.location = '/404';
    }
    return Promise.reject((response && response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import roleReducer from './slices/role';
import globalConfigReducer from './slices/global-configuration';
import branchReducer from './slices/branch';
import preferenceReducer from './slices/preference';
import userReducer from './slices/user';
import collectorReducer from './slices/collectors';
import approverReducer from './slices/approvers';
import disbursementOfficerReducer from './slices/disbursement-officers';
import assessorReducer from './slices/assessors';
import creditInvestigatorReducer from './slices/credit-investigators';
import loanReducer from './slices/loan';
import loanAnalyticsReducer from './slices/loan-analytics';
import paymentSchedulesReducer from './slices/payment-schedules';
import loansForApprovalReducer from './slices/loans-for-approval';
import loansForDisbursementReducer from './slices/loans-for-disbursement';
import draftLoansReducer from './slices/draft-loans';
import penaltyWaiverReducer from './slices/penalty-waiver';
import borrowerReducer from './slices/borrowers';
import paymentReducer from './slices/payments';
import changesReducer from './slices/unclaimed-changes';
import borrowerLoanReducer from './slices/borrower-loans';
import portalBranchReducer from './slices/portal-branch';
import onlineApplicationReducer from './slices/online-applications';
import notificationReducer from './slices/notification';
import rootCollectorReducer from './slices/root-collectors';
import transferRequestReducer from './slices/transfer-requests';
import clusterReducer from './slices/clusters';
import temporaryCollectorReducer from './slices/temporary-collectors';
import paymentReversalReducer from './slices/payment-reversal';
import agentReducer from './slices/agents';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
  globalconfig: globalConfigReducer,
  branch: branchReducer,
  role: roleReducer,
  user: userReducer,
  collector: collectorReducer,
  approver: approverReducer,
  disbursementOfficer: disbursementOfficerReducer,
  assessor: assessorReducer,
  creditInvestigator: creditInvestigatorReducer,
  preference: preferenceReducer,
  loanAnalytics: loanAnalyticsReducer,
  loan: loanReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  schedule: paymentSchedulesReducer,
  forApprovalLoans: loansForApprovalReducer,
  forDisbursementLoans: loansForDisbursementReducer,
  draftLoans: draftLoansReducer,
  waivers: penaltyWaiverReducer,
  borrowers: borrowerReducer,
  payments: paymentReducer,
  changes: changesReducer,
  borrowerLoans: borrowerLoanReducer,
  portalBranches: portalBranchReducer,
  onlineApplications: onlineApplicationReducer,
  notifications: notificationReducer,
  rootCollector: rootCollectorReducer,
  transferRequests: transferRequestReducer,
  clusters: clusterReducer,
  temporaryCollectors: temporaryCollectorReducer,
  paymentReversals: paymentReversalReducer,
  product: persistReducer(productPersistConfig, productReducer),
  agent: agentReducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };

import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { HOST_FILE_URL } from '../../../config';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import { fToNow } from '../../../utils/formatTime';
import createAvatar from '../../../utils/createAvatar';
// apis
import { list, read, readAll } from '../../../apis/notification';
// data
import { useSelector } from '../../../redux/store';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

import bell from '../../../assets/audio/bell-600.wav';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { notifications } = useSelector((state) => state.notifications);

  const totalUnRead = notifications?.length || 0;

  const [open, setOpen] = useState(null);
  const [audio] = useState(new Audio(bell));
  const [playing, setPlaying] = useState(false);
  const [prevCount, setPrevCount] = useState(totalUnRead);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      await readAll();
      setOpen(null);
      setPrevCount(0);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleRead = async (id, referenceId, type) => {
    try {
      await read(id);
      setOpen(null);
      setPrevCount(prevCount !== 0 && prevCount - 1);

      switch (type) {
        case 'LOAN_APPROVAL':
        case 'LOAN_FOR_DISBURSEMENT':
          navigate(PATH_DASHBOARD.loan.view(referenceId));
          return '';
        case 'ACCOUNT_TRANSFER':
          navigate(PATH_DASHBOARD.borrower.view(referenceId));
          return '';
        default:
          return '';
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      return '';
    }
  };

  useEffect(() => {
    if (totalUnRead > 0 && totalUnRead > prevCount && playing === false) setPlaying(true);
  }, [totalUnRead, prevCount, playing]);

  useEffect(() => {
    if (playing) {
      audio.play();
      setPlaying(false);
      setPrevCount(prevCount + 1);
    }
  }, [playing, prevCount, audio]);

  useEffect(() => {
    async function getList() {
      try {
        await list();
      } catch (error) {
        enqueueSnackbar('Get notification list failed!', { variant: 'error' });
      }
    }
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getList() {
      try {
        await list();
      } catch (error) {
        enqueueSnackbar('Get notification list failed!', { variant: 'error' });
      }
    }
    const interval = setInterval(() => {
      getList();
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75, overflowX: 'hidden' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        {totalUnRead > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Scrollbar sx={{ maxHeight: 260 }}>
              <List disablePadding sx={{ maxHeight: 260 }}>
                {notifications.map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} onRead={handleRead} />
                ))}
              </List>
            </Scrollbar>
          </>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.object,
  onRead: PropTypes.func,
};

function NotificationItem({ notification, onRead }) {
  const { title, avatar } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => onRead(notification.id, notification.referenceId, notification.type)}
    >
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createTime)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const { type, user } = notification;

  const getSubTitle = () => {
    switch (type) {
      case 'LOAN_APPROVAL':
        return 'asked you to approve a loan';
      case 'LOAN_FOR_DISBURSEMENT':
        return 'asked you to disburse a loan';
      case 'ACCOUNT_TRANSFER':
        return 'A borrower account was transfered to your branch';
      default:
        return '';
    }
  };

  const title = (
    <Typography variant="subtitle2">
      {`${user.name}`}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(getSubTitle())}
      </Typography>
    </Typography>
  );

  const avatar = (
    <Avatar
      alt={user?.name}
      src={user?.photo ? `${HOST_FILE_URL}/external/images/accounts/thumbnail/${user?.photo?.uri}` : null}
      color={user?.photo !== null ? 'default' : createAvatar(user?.name).color}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );

  return {
    avatar,
    title,
  };
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  loans: [],
};

const slice = createSlice({
  name: 'loans-for-disbursement',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.loans || state.loans.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getLoansSuccess(state, action) {
      state.isLoading = false;
      state.loans = action.payload;
      state.isInitializing = false;
    },
  },
});

export const { startLoading, hasError, getLoansSuccess } = slice.actions;

export default slice.reducer;

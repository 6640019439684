// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function PaymentPendingIllustration({ ...other }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="360.55351"
        height="193.87026"
        viewBox="0 0 760.55351 493.87026"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M980.27676,434.93513a77.99644,77.99644,0,0,1-72,77.77c-.66016.05-1.33008.1-2,.13v-74.03a4.95928,4.95928,0,0,0-1.83985-3.86,1.2473,1.2473,0,0,0-.22021-.18,1.86108,1.86108,0,0,0-.23-.16,5.00053,5.00053,0,0,0-4.77-.35l-.83008-1.82a7.00682,7.00682,0,0,1,2.89014-.63l-56.96-49.07c.43994-.5.8999-.99,1.35986-1.48a78.00688,78.00688,0,0,1,134.6001,53.68Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#f2f2f2"
        />
        <path
          d="M239.50094,488.121a10.08716,10.08716,0,0,1,9.73047-12.02335l44.96213-53.52339,11.225,14.35185-45.82271,49.8125a10.14185,10.14185,0,0,1-20.09484,1.38239Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#ffb8b8"
        />
        <path
          d="M453.01,422.24334a10.52678,10.52678,0,0,0-.83241,1.43331l-49.09421,7.01769-7.45492-9.4646-15.75426,9.38387,14.809,23.08909,59.87528-17.65021A10.49579,10.49579,0,1,0,453.01,422.24334Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#ffb8b8"
        />
        <polygon
          points="149.426 398.009 158.114 406.659 195.614 377.267 182.792 364.5 149.426 398.009"
          fill="#ffb8b8"
        />
        <path
          d="M358.89408,608.16256h38.53071a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H373.781a14.8869,14.8869,0,0,1-14.8869-14.8869v0A0,0,0,0,1,358.89408,608.16256Z"
          transform="translate(-7.95298 1115.59869) rotate(-135.12316)"
          fill="#2f2e41"
        />
        <polygon points="103.246 480.98 115.506 480.98 121.338 433.692 103.244 433.692 103.246 480.98" fill="#ffb8b8" />
        <path
          d="M320.34206,680.54149h38.53072a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H335.22894a14.88688,14.88688,0,0,1-14.88688-14.88688v0A0,0,0,0,1,320.34206,680.54149Z"
          transform="translate(459.52299 1172.88947) rotate(179.99738)"
          fill="#2f2e41"
        />
        <path
          d="M320.30408,647.52195l-13.96447-102.9868a128.9498,128.9498,0,0,1,2.00122-46.71645c2.86561-12.31181,6.73692-20.84015,11.50674-25.34671a4.71831,4.71831,0,0,1,2.74314-1.25267l46.10641-4.80292a4.70612,4.70612,0,0,1,4.51012,2.25379l1.51568,2.52571a4.698,4.698,0,0,1,.44225.97362l9.74817,30.2199a3.61643,3.61643,0,0,0,1.80848,2.12649c15.348,7.8358,65.20326,34.28751,62.35013,45.7-2.68077,10.72306-27.77924,28.10971-35.40779,33.18252a4.66064,4.66064,0,0,1-5.24009-.03972L395.7609,574.695a4.69345,4.69345,0,0,1-.13367-7.65147l8.73254-6.43445a3.65083,3.65083,0,0,0-.95682-6.38353l-46.89849-16.4558a3.64989,3.64989,0,0,0-4.84646,3.14695l-8.64724,105.68767a4.70514,4.70514,0,0,1-4.4429,4.30389l-13.37939.66911c-.08147.00407-.16218.00611-.24289.00611A4.66262,4.66262,0,0,1,320.30408,647.52195Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#2f2e41"
        />
        <circle cx="121.72635" cy="153.52379" r="24.56103" fill="#ffb8b8" />
        <path
          d="M318.477,468.26486l-18.07918-65.9648.3096-.20877c.38828-.26174,39.3565-25.99342,84.98043-5.25715l.82722.47459L375.8022,465.77581a6.78561,6.78561,0,0,1-6.44437,5.77654L325.275,473.248c-.08886.003-.17746.00509-.26555.00509A6.7513,6.7513,0,0,1,318.477,468.26486Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#ccc"
        />
        <path
          d="M299.70988,445.24424l-15.05725-17.31536a4.68627,4.68627,0,0,1-.10643-6.03217l15.74062-19.4449,12.08343,3.29564,6.40618,22.42178-11.68064,16.687a4.66526,4.66526,0,0,1-3.59888,1.99511c-.08427.00407-.168.00611-.2518.00611A4.66009,4.66009,0,0,1,299.70988,445.24424Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#ccc"
        />
        <path
          d="M382.97476,441.637a4.6652,4.6652,0,0,1-3.59888-1.99511l-11.68064-16.687,5.61837-23.60242,11.12547-3.03437,2.076.99066,15.41039,19.37362a4.68626,4.68626,0,0,1-.10643,6.03216l-15.05725,17.31537a4.66011,4.66011,0,0,1-3.53523,1.61319C383.14305,441.64307,383.05878,441.641,382.97476,441.637Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#ccc"
        />
        <path
          d="M906.27676,433.9051a.9803.9803,0,0,1-.24024.68006.99982.99982,0,0,1-1.40967.11l-3.35009-2.89-56.96-49.07L684.557,245.08516a5.01961,5.01961,0,0,0-6.54.01l-215.14014,186.91-1.93994,1.68a.96621.96621,0,0,1-.25.16,1,1,0,0,1-.51025.08,1.02484,1.02484,0,0,1-.6499-.33.99744.99744,0,0,1,.08984-1.41l.27-.24,216.82031-188.36a7.0466,7.0466,0,0,1,9.15967-.02l159.81006,137.69,60.25,51.92A1.01574,1.01574,0,0,1,906.27676,433.9051Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#3f3d56"
        />
        <path
          d="M784.807,463.43513l-2.63037-7.24-9.06006-25-19.52-53.83-21.81982-60.17-39.3501-108.53a8.51614,8.51614,0,0,0-10.89014-5.09l-227.50976,82.49a8.51217,8.51217,0,0,0-5.08985,10.89l55.79,153.86.94971,2.63,58.56006,26.54,16.46,7.46,2.66992,1.21,69.69043,31.59,2.69971-.98,45.71-16.57,68.95019-25,9.29-3.37A8.51789,8.51789,0,0,0,784.807,463.43513Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#e6e6e6"
        />
        <path
          d="M689.40664,335.27516a12.52171,12.52171,0,0,0-16.02-7.49005l-90.02,32.65-43.46973,15.76a12.49841,12.49841,0,1,0,8.52,23.5l34.94971-12.67,98.55029-35.73A12.52158,12.52158,0,0,0,689.40664,335.27516Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M705.25674,378.99513a12.52187,12.52187,0,0,0-16.02-7.49l-15.25,5.53-90.62012,32.86-27.61963,10.01a12.50311,12.50311,0,0,0,8.52,23.51l19.09961-6.93,114.40039-41.48c.25977-.09.50977-.2.75977-.31A12.49821,12.49821,0,0,0,705.25674,378.99513Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M721.27676,423.17512a12.5107,12.5107,0,0,0-16.00977-7.49l-42.77,15.51-33.11035,12.01-46.02,16.68-11.59961,4.21a12.52729,12.52729,0,0,0-8.13037,13.42,12.3647,12.3647,0,0,0,.6001,2.47l16.46,7.46,2.66992-.97h.02l83.51025-30.28,46.88965-17a12.51132,12.51132,0,0,0,7.49024-16.02Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M627.54678,292.29512a12.52192,12.52192,0,0,0-16.02-7.49l-51.7002,18.74a12.50311,12.50311,0,0,0,8.52,23.51l16.36035-5.93,10.82959-3.93,24.52051-8.89A12.51107,12.51107,0,0,0,627.54678,292.29512Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M833.8666,317.19514h-242a8.502,8.502,0,0,0-7.15967,3.93,8.4141,8.4141,0,0,0-1.34033,4.57v162.96l69.69043,31.59,9.81982,4.45,15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22,45.24023-20.51,48.90967-22.16v-134.33A8.50539,8.50539,0,0,0,833.8666,317.19514Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#ffa24b"
        />
        <path
          d="M783.8666,431.19514h-142a12.4967,12.4967,0,0,0-12.48,12.01,4.01078,4.01078,0,0,0-.02.49,12.51761,12.51761,0,0,0,12.5,12.5h142a12.5,12.5,0,0,0,0-25Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M791.95693,480.67512a12.41094,12.41094,0,0,0-8.09033-2.98h-142a12.5,12.5,0,0,0,0,25H748.2167l45.24023-20.51A12.58317,12.58317,0,0,0,791.95693,480.67512Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M662.87685,524.69514l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M752.10683,377.9051a12.506,12.506,0,0,0-11.74023-8.21h-55a12.50937,12.50937,0,0,0-11.37988,7.34,12.35787,12.35787,0,0,0-1.12012,5.16,12.51761,12.51761,0,0,0,12.5,12.5h55a12.49876,12.49876,0,0,0,11.74023-16.79Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#fff"
        />
        <path
          d="M743.3666,502.69514l-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21h-4.8501l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22Zm0,0-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21h-4.8501l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22Zm0,0-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21h-4.8501l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22Zm162.91016-68.79a7.63845,7.63845,0,0,0-1.21-.98,6.96066,6.96066,0,0,0-6.68018-.49l-106.42969,48.24-48.59033,22.02-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21-11.97021-5.43-72.37012-32.79-.02-.00995-19.73-8.95-58.90967-26.7-40.56006-18.38a6.10738,6.10738,0,0,0-1.29-.43,6.70854,6.70854,0,0,0-2.99023-.06,7.017,7.017,0,0,0-5.60986,6.86v251.13a7.00778,7.00778,0,0,0,7,7h440a7.00778,7.00778,0,0,0,7-7v-251.13A6.97174,6.97174,0,0,0,906.27676,433.9051Zm0,256.03a5.002,5.002,0,0,1-5,5h-440a5.002,5.002,0,0,1-5-5v-251.13a5.00087,5.00087,0,0,1,2.29-4.2,5.1681,5.1681,0,0,1,1.60986-.68,4.51064,4.51064,0,0,1,.51025-.08,3.89766,3.89766,0,0,1,.58985-.04,4.96993,4.96993,0,0,1,2.06.45l42.33985,19.19,58.56006,26.54,16.46,7.46,2.66992,1.21,69.69043,31.59,9.81982,4.45,15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22,45.24023-20.51,105.75977-47.93a5.00053,5.00053,0,0,1,4.77.35,1.86108,1.86108,0,0,1,.23.16,1.2473,1.2473,0,0,1,.22021.18,4.95928,4.95928,0,0,1,1.83985,3.86Zm-162.91016-187.24-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21h-4.8501l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22Zm0,0-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21h-4.8501l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22Zm-162.66992-15.25,2.66992,1.21v-2.18Zm162.66992,15.25-48.54,22-11.48975,5.21a5.0163,5.0163,0,0,1-4.12011,0l-11.48975-5.21h-4.8501l15.50977,7.03a7.02024,7.02024,0,0,0,5.78027,0l15.50977-7.03,48.54-22Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#3f3d56"
        />
        <path
          d="M395.2492,696.93513h-113a1,1,0,0,1,0-2h113a1,1,0,1,1,0,2Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#3f3d56"
        />
        <path
          d="M332.11093,374.55038a10.70173,10.70173,0,0,1,.98975-19.52749c7.4115-2.86009,15.72028,2.97689,23.48923,1.31739,7.74334-1.654,12.22267-10.91139,10.3744-18.61068s-8.57535-13.49851-15.99322-16.26805a34.47953,34.47953,0,0,0-29.74118,2.72614,31.75119,31.75119,0,0,0-14.9446,25.63373,29.43748,29.43748,0,0,0,14.24362,25.79074c1.97455,1.13818,4.27292,2.052,6.49366,1.53951A18.10043,18.10043,0,0,0,332.11093,374.55038Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#2f2e41"
        />
        <path
          d="M324.15161,323.58907c-6.53544-8.66841-15.3577-15.78964-25.62357-19.32028s-21.99719-3.24718-31.6387,1.74227-16.8318,14.89868-17.58232,25.72874c-1.24911,18.02472,14.15247,33.34642,15.7331,51.3451a34.52622,34.52622,0,0,1-45.31688,35.77324,36.35,36.35,0,0,0,28.01709,7.95123,33.78616,33.78616,0,0,0,24.04722-16.18126c3.46629-6.02251,4.88959-13.01822,5.44248-19.945a88.618,88.618,0,0,0-3.46772-32.49333c-1.72753-5.74111-4.07041-11.64922-2.97488-17.54367a15.96592,15.96592,0,0,1,12.43543-12.45366c4.95332-.92812,10.01775.58363,14.74742,2.32344s9.49823,3.75319,14.53643,3.86838,10.5258-2.191,12.47287-6.8392Z"
          transform="translate(-219.72324 -203.06487)"
          fill="#2f2e41"
        />
      </svg>
    </Box>
  );
}

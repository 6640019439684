import PropTypes from 'prop-types';
import { useState } from 'react';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { List, Stack, IconButton, ListItemText, ListItem } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
//
import Image from '../Image';
import Iconify from '../Iconify';
import { DialogAnimate, varFade } from '../animate';

import loadingImage from '../../assets/loader/loader.gif';

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.id,
    name: file.name,
    size: file.size,
    preview: file.preview || file.uri,
  };
};

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  showPreview: PropTypes.bool,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  // onRemoveAll: PropTypes.func,
};

export default function MultiFilePreview({ showPreview = false, files, onRemove, disabled = false }) {
  const hasFile = files?.length > 0;

  const [openImage, setOpenImage] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleOpen = (file) => {
    if (file.preview) {
      setSelected(file);
      setOpenImage(true);
    } else {
      setSelected(null);
    }
  };

  return (
    <>
      {hasFile && selected !== null && (
        <DialogAnimate open={openImage} onClose={() => setOpenImage(false)} fullWidth sx={{ overflowY: 'hidden' }}>
          <Stack>
            <Image
              alt="Full preview"
              src={isString(selected) ? selected : selected.preview}
              sx={{ height: '100%', width: '100%', objectFit: 'fill' }}
            />
          </Stack>
        </DialogAnimate>
      )}

      {hasFile && (
        <List
          component={Stack}
          direction={showPreview ? 'row' : 'column'}
          disablePadding
          sx={{ ...(hasFile && { my: 3 }) }}
        >
          <AnimatePresence>
            {files?.map((file) => {
              const { key, name, size, preview } = getFileData(file);

              if (showPreview) {
                return (
                  <ListItem
                    key={key}
                    component={m.div}
                    {...varFade().inRight}
                    sx={{
                      p: 0,
                      m: 0.5,
                      width: 80,
                      height: 80,
                      borderRadius: 1.25,
                      overflow: 'hidden',
                      position: 'relative',
                      display: 'inline-flex',
                      border: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <Image
                      alt="preview"
                      src={preview || loadingImage}
                      ratio="1/1"
                      onClick={() => handleOpen(file)}
                      sx={{
                        '&:hover': {
                          transform: 'scale(1.25)',
                          transition: 'transform .75s ease',
                        },
                      }}
                    />
                    {!disabled && (
                      <IconButton
                        size="small"
                        onClick={() => onRemove(file)}
                        sx={{
                          top: 6,
                          p: '2px',
                          right: 6,
                          position: 'absolute',
                          color: 'common.white',
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                          '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                          },
                        }}
                      >
                        <Iconify icon={'eva:close-fill'} />
                      </IconButton>
                    )}
                  </ListItem>
                );
              }

              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    my: 1,
                    px: 2,
                    py: 0.75,
                    borderRadius: 0.75,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    width: '100%',
                  }}
                >
                  <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size || 0)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />

                  <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                </ListItem>
              );
            })}
          </AnimatePresence>
        </List>
      )}

      {/* {hasFile && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          <Button size="small" variant="contained">
            Upload files
          </Button>
        </Stack>
      )} */}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  collectors: [],
};

const slice = createSlice({
  name: 'internal-user-collectors',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.collectors || state.collectors.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getCollectorsSuccess(state, action) {
      state.isLoading = false;
      state.collectors = action.payload;
      state.isInitializing = false;
    },
  },
});

export function getCollectors() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/internal/accounts/collectors`);
      dispatch(slice.actions.getCollectorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  users: [],
};

const slice = createSlice({
  name: 'internal-user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.users || state.users.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
      state.isInitializing = false;
    },

    createUserSuccess(state, action) {
      state.isLoading = false;
      state.users.push({
        id: action.payload.id,
        username: `${action.payload.firstName.toLowerCase()}.${action.payload.lastName.toLowerCase()}`,
        role: {
          id: action.payload.roleId,
          name: action.payload.roleName,
        },
        personalDetails: {
          firstName: action.payload.firstName,
          middleName: action.payload.middleName,
          lastName: action.payload.lastName,
        },
        status: 'ACTIVE',
        createTime: new Date().toISOString(),
        updateTime: new Date().toISOString(),
      });
    },

    updateUserSuccess(state, action) {
      state.isLoading = false;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      let updatedUser = state.users[index];
      updatedUser = {
        ...updatedUser,
        role: {
          id: action.payload.roleId,
          name: action.payload.roleName,
        },
        personalDetails: {
          ...updatedUser.personalDetails,
          firstName: action.payload.firstName,
          middleName: action.payload.middleName,
          lastName: action.payload.lastName,
        },
        updateTime: new Date().toISOString(),
      };
      state.users[index] = updatedUser;
    },

    postUserPhotoSuccess(state, action) {
      state.isLoading = false;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      let updatedUser = state.users[index];
      const { photo: userPhoto } = action.payload;
      updatedUser = {
        ...updatedUser,
        photo: userPhoto,
      };
      state.users[index] = updatedUser;
    },
    updateUserStatusSuccess(state, action) {
      state.isLoading = false;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      const updatedUser = state.users[index];
      updatedUser.status = action.payload.status;
      state.users[index] = updatedUser;
    },
    updateUserPromotionSuccess(state, action) {
      state.isLoading = false;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      const updatedUser = state.users[index];
      updatedUser.superUser = action.payload.superUser;
      state.users[index] = updatedUser;
    },
  },
});

export function getUsers(name, roleId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/internal/accounts?name=${name}&roleId=${roleId}&size=150`);
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const {
  createUserSuccess,
  updateUserSuccess,
  postUserPhotoSuccess,
  updateUserStatusSuccess,
  updateUserPromotionSuccess,
} = slice.actions;

export default slice.reducer;

import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, InputAdornment } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
};

export default function RHFTextField({ name, startAdornment, endAdornment, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          InputProps={{
            startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
            endAdornment: <InputAdornment position="start">{endAdornment}</InputAdornment>,
          }}
          {...other}
        />
      )}
    />
  );
}

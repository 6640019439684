import { useState, useEffect } from 'react';
import { MenuItem, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// apis
import { list as listBranches } from '../../../apis/branch';
// data
import { useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import MenuPopover from '../../../components/MenuPopover';
import Iconify from '../../../components/Iconify';

export default function BranchFilter() {
  const { isRootUser, activeBranch, setActiveBranch } = useAuth();

  const { branches, isInitializing: isBranchInit } = useSelector((state) => state.branch);

  const [filterBranch, setFilterBranch] = useState(activeBranch);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (isRootUser) {
      listBranches();
    }
  }, [isRootUser]);

  return (
    <>
      <LoadingButton
        loading={isBranchInit}
        size="small"
        endIcon={<Iconify icon="akar-icons:chevron-down" />}
        sx={{ width: 180, textAlign: 'right' }}
        onClick={handleOpen}
      >
        {branches.find((branch) => branch.id === filterBranch)?.name}
      </LoadingButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {branches?.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === filterBranch}
              onClick={() => {
                setFilterBranch(option.id);
                setActiveBranch(option.id);
                handleClose();
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}

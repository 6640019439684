import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  loans: [],
};

const slice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (state.loans.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getLoansSuccess(state, action) {
      state.isLoading = false;
      state.loans = action.payload;
      state.isInitializing = false;
    },

    getLoanSuccess(state, action) {
      state.isLoading = false;
      state.loans.push(action.payload);
      state.isInitializing = false;
    },

    deleteLoanById(state, action) {
      state.isLoading = false;
      state.loans = state.loans.filter((loan) => loan.id !== action.payload);
    },
  },
});

export function getLoans(productType, searchTerm, startDate, endDate, status) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/loans?productType=${productType}&searchTerm=${searchTerm}&startDateFilter=${startDate}&endDateFilter=${endDate}&statuses=${status}`
      );
      dispatch(slice.actions.getLoansSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteLoan(id) {
  return async () => {
    dispatch(slice.actions.deleteLoanById(id));
  };
}

export const { getLoanSuccess } = slice.actions;

export default slice.reducer;

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link as MaterialLink, Divider, Typography, Stack } from '@mui/material';
// data
import { useSelector, useDispatch } from '../../redux/store';
import { getBranches } from '../../redux/slices/portal-branch';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
// layouts
import Container from './MainContainer';
import palette from '../../theme/palette';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'QUICK LINKS',
    children: [
      { name: 'Home', href: PATH_PAGE.root },
      { name: 'About Us', href: PATH_PAGE.about },
      { name: 'Our Products', href: PATH_PAGE.products },
      { name: 'Loan Application', href: PATH_PAGE.loanApplication },
      { name: 'Contact Us', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'FOLLOW US',
    children: [
      { name: 'Facebook', href: 'https://www.facebook.com/lcorelendinginvestorinc' },
      { name: 'Twitter', href: '#' },
      { name: 'Instagram', href: 'https://instagram.com/lcorelendinginvestorinc?igshid=YmMyMTA2M2Y=' },
      { name: 'TikTok', href: '#' },
    ],
  },
  {
    headline: 'OUR BRANCHES',
    children: [],
  },
];

const SOCIALLINKS = {
  facebook: LINKS[1].children[0].href,
  instagram: LINKS[1].children[2].href,
  tiktok: LINKS[1].children[3].href,
  twitter: LINKS[1].children[1].href,
};

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[900],
  minHeight: 520,
  paddingBottom: 25,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const dispatch = useDispatch();

  const { isInitializing, branches } = useSelector((state) => state.portalBranches);

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  if (isInitializing) {
    return <LoadingScreen />;
  }

  LINKS[2].children = branches.map((branch) => ({ name: branch?.address?.addressLine, href: '#' }));

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body1" sx={{ pr: { md: 5 }, color: 'common.white' }}>
              L-Core Lending Investor Inc. is a financial organization in the Philippines that caters to the financial
              needs of small businesses, pensioners and salaried individuals.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} links={SOCIALLINKS} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" sx={{ color: 'common.white' }}>
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <>
                      <Stack key={link.name} sx={{ flexDirection: 'row' }}>
                        {list.headline === 'OUR BRANCHES' && (
                          <Stack sx={{ flexDirection: 'column' }}>
                            <Iconify icon="carbon:location-filled" sx={{ marginRight: 2, color: 'primary.main' }} />
                          </Stack>
                        )}

                        {list.headline === 'FOLLOW US' ? (
                          <MaterialLink href={link.href}> {link.name}</MaterialLink>
                        ) : (
                          <Link
                            to={link.href}
                            variant="body2"
                            style={{
                              textDecoration: 'none',
                              textAlign: { xs: 'center', lg: 'left' },
                              color: list.headline === 'OUR BRANCHES' ? 'white' : palette.dark.primary.main,
                            }}
                          >
                            {link.name}
                          </Link>
                        )}
                      </Stack>
                    </>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            color: 'common.white',
            mt: 10,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
          display={{ xl: 'inline' }}
        >
          © 2022 L-CORE LENDING INVESTOR INC.
          <Typography>All rights reserved</Typography>
        </Typography>
      </Container>
    </RootStyle>
  );
}

import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelectMenu.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  data: PropTypes.array,
  sortedObj: PropTypes.bool,
};

export default function RHFSelectMenu({ name, data, sortedObj, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          {data && data.length > 0 && sortedObj ? (
            <TextField
              {...field}
              select
              fullWidth
              SelectProps={{ native: true }}
              error={!!error}
              helperText={error?.message}
              data={data !== undefined ? data : []}
              {...other}
            >
              {[...data]
                .sort((a, b) => ((a.name || a.label) > (b.name || b.label) ? 1 : -1))
                .map((option) => (
                  <MenuItem
                    key={option.code || option.id || option.name}
                    value={option.code || option.id || option.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.name || option.label}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <TextField
              {...field}
              select
              fullWidth
              SelectProps={{ native: true }}
              error={!!error}
              helperText={error?.message}
              data={data !== undefined ? data : []}
              {...other}
            >
              {data.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        </>
      )}
    />
  );
}

export const payloadToData = (payload) => {
  let primaryNumber = '';
  if (payload?.contactInformations) {
    const mobileNoNode = payload?.contactInformations?.find(
      (item) => item.type === 'MOBILE_NO' && item.subType === 'PRIMARY'
    );
    primaryNumber = mobileNoNode?.value || '';
  }

  let secondaryNumber = '';
  if (payload?.contactInformations) {
    const mobileNoNode = payload?.contactInformations?.find(
      (item) => item.type === 'MOBILE_NO' && item.subType === 'SECONDARY'
    );
    secondaryNumber = mobileNoNode?.value || '';
  }

  return {
    name: payload?.name,
    addressLine: payload?.address.addressLine || '',
    contactNo1: primaryNumber,
    contactNo2: secondaryNumber,
    bankAndBranch: payload?.bank,
    locationLon: payload?.location?.lon,
    locationLat: payload?.location?.lat,
    baseUrl: payload?.dashboardApi?.baseUrl,
    dashboardId: payload?.dashboardApi?.dashboardId,
  };
};

export const dataToPayload = (data) => ({
  name: data.name,
  address: {
    addressLine: data.addressLine,
  },
  contactInformations: [
    {
      type: 'MOBILE_NO',
      subType: 'PRIMARY',
      value: data.contactNo1,
    },
    {
      type: 'MOBILE_NO',
      subType: 'SECONDARY',
      value: data.contactNo2,
    },
  ],
  bank: data.bankAndBranch,
  location: {
    lon: data.locationLon,
    lat: data.locationLat,
  },
  dashboardApi: {
    baseUrl: data.baseUrl,
    dashboardId: data.dashboardId,
  },
});

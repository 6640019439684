import { HOST_FILE_URL } from '../config';

export const payloadToDisplay = (payload) => {
    const { id, personalDetails, photo, attachmentDetails, status } = payload;
    // ################## Personal Details ##################
    let email = '';
    if (personalDetails?.contactInformations) {
      const emailNode = personalDetails?.contactInformations?.find((item) => item.type === 'EMAIL');
      email = emailNode?.value || '';
    }
  
    let contactNo = '';
    if (personalDetails?.contactInformations) {
      const mobileNoNode = personalDetails?.contactInformations?.find(
        (item) => item.type === 'MOBILE_NO' && item.subType === 'PRIMARY'
      );
      contactNo = mobileNoNode?.value || '';
    }
  
    let address = '';
    if (personalDetails?.addresses) {
      const addressNode = personalDetails?.addresses?.find((item) => item.addressType === 'PRESENT');
      address = addressNode?.addressLine;
    }
  
    // ################## Attachment ##################
    let agentIdFrontUri = '';
    let agentIdBackUri = '';
  
    if (attachmentDetails) {
      const agentIdFront = attachmentDetails?.find(
        (item) =>
          item.ownerType === 'AGENT' &&
          item.subType === 'FRONT_SECTION'
      );
      const agentIdBack = attachmentDetails?.find(
        (item) =>
          item.ownerType === 'AGENT' && 
          item.subType === 'BACK_SECTION'
      );
  
      if (agentIdFront) {
        agentIdFrontUri = `${HOST_FILE_URL}/external/attachments/agents/${agentIdFront.uri}`;
      }
  
      if (agentIdBack) {
        agentIdBackUri = `${HOST_FILE_URL}/external/attachments/agents/${agentIdBack.uri}`;
      }
    }
  
    return {
      id,
      status,
      firstName: personalDetails?.firstName,
      middleName: personalDetails?.middleName,
      lastName: personalDetails?.lastName,
      email,
      contactNo,
      address,
      photo,
      frontId: agentIdFrontUri,
      backId: agentIdBackUri
    };
  };
  
  export const dataToPayload = (data) => {

    const contactInformationArray = [];
    if (data.email?.length > 0) {
      contactInformationArray.push({
        type: 'EMAIL',
        subType: 'PRIMARY',
        value: data.email,
      });
    }
  
    if (data.contactNo?.length > 0) {
      contactInformationArray.push({
        type: 'MOBILE_NO',
        subType: 'PRIMARY',
        value: data.contactNo,
      });
    }
  
    const addressesArray = [];
    addressesArray.push({
      addressLine: data.address,
      addressType: 'PRESENT',
    });
  
    return {
      personalDetails: {
        firstName: trim(data.firstName),
        middleName: trim(data.middleName),
        lastName: trim(data.lastName),
        contactInformations: contactInformationArray,
        addresses: addressesArray,
      }
    };
};

const trim = (data) => {
    if (data) {
      if (data instanceof String) {
        data = data.trim();
        if (data.length > 0) {
          return data;
        }
      } else {
        return data;
      }
    }
    return null;
};
  
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function PaymentCompleteIllustration({ ...other }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="193"
        height="176.89838"
        viewBox="0 0 693 576.89838"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M426.43,605.52288H923.81a21.68883,21.68883,0,0,0,21.69-21.69v-287.62a21.34934,21.34934,0,0,0-.76-5.69,21.68267,21.68267,0,0,0-20.93-16H729.54a60.38008,60.38008,0,0,0-8.18,25.01c-2.41,25.69,12.32,49.44,25.5,71.63,13.18,22.18994,25.75,47.99,18.77,72.84-4.72,16.76-17.51,28.89-33.38,36.16a93.66465,93.66465,0,0,1-48.59,7.4,152.11784,152.11784,0,0,1-29.26-6.68c-12.47-4.05-24.65-9.3-36.81-14.61-.01,0-.01,0-.02-.01-.21-.09-.43-.19-.64-.28-9.41-4.11-18.81-8.23-28.35-11.86a173.13923,173.13923,0,0,0-34.62-9.54c-17.99-2.82-36.13-1.8-52.18,6.36-26.16,13.29-40.18,42.01-51.15,69.23C439.55,547.69286,429.62,576.13286,426.43,605.52288Z"
          transform="translate(-253.5 -161.55081)"
          fill="#70be10"
        />
        <path
          d="M254.5,495.49285v88.34a21.68883,21.68883,0,0,0,21.69,21.69h82.68c1.43-13.33.23-26.61-5.02-39-11.44-27.02-39.12-43-65.50995-55.83C277.25,505.30284,265.97,500.10283,254.5,495.49285Z"
          transform="translate(-253.5 -161.55081)"
          fill="#70be10"
        />
        <path
          d="M341.147,585.12295v.00011a21.69,21.69,0,0,0,21.69,21.69h82.68c1.43-13.33.23-26.61-5.02-39-2.68964-6.35263-8.97221-13.38957-17.15865-20.49782C391.22,519.427,341.147,542.58653,341.147,585.12295Z"
          transform="translate(-253.5 -161.55081)"
          fill="#70be10"
        />
        <path
          d="M253.5,583.83245V296.21282a22.7157,22.7157,0,0,1,22.68994-22.68994H923.81006A22.7157,22.7157,0,0,1,946.5,296.21282V583.83245a22.7158,22.7158,0,0,1-22.68994,22.69043H276.18994A22.7158,22.7158,0,0,1,253.5,583.83245Zm22.68994-308.30957A20.71309,20.71309,0,0,0,255.5,296.21282V583.83245a20.7132,20.7132,0,0,0,20.68994,20.69043H923.81006A20.7132,20.7132,0,0,0,944.5,583.83245V296.21282a20.71309,20.71309,0,0,0-20.68994-20.68994Z"
          transform="translate(-253.5 -161.55081)"
          fill="#3f3d56"
        />
        <path
          d="M467.81,604.81307h29.41c5.03-16.93645,12.29-34.889,18.92-51.249,12.72-31.36814,28.97-64.4657,59.3-79.782,12.95-6.54,27.07-9.09434,41.49-8.95524,19.92.18885,40.4,5.53615,59.15,12.62281,7.67,2.89234,15.27,6.07284,22.84,9.323,28.52,12.25506,56.73,25.60342,87.4,29.21135,38.81,4.56207,84.44-12.8017,95.04-50.20295,8.09-28.63486-6.48-58.363-21.76-83.93664-15.28-25.57357-32.36-52.93624-29.56995-82.54513.01-.09936.02-.20874.04-.30811,1.05-10.635,7.4-18.278,16.27-23.178H807.72c-.51,27.621,15.47,52.00174,29.85,76.07448,15.28,25.56367,29.85,55.30177,21.76,83.93663-10.6,37.39131-98.373-6.439-137.183-11.01111-10.96-1.28214,6.45,3.27992-4,0-16.39-5.13862-18.207,41.99167-34.157,35.074-9.93-4.31364-19.87-8.59744-29.94-12.40411-4.17-1.58036-8.43-3.0613-12.74-4.43291-.01,0-.01,0-.02-.01-28.94-9.16392-60.59-12.73213-87.33.51686-.18.08942-.37.1789-.55.26832-30.33,15.3064-46.58,48.404-59.3,79.7721C483.6,549.52877,472.99,577.46034,467.81,604.81307Z"
          transform="translate(-253.5 -161.55081)"
          fill="#3f3d56"
        />
        <path
          d="M468.1296,398.661H276.838a5.91,5.91,0,1,0,0,11.82H468.1296a5.91,5.91,0,1,0,0-11.82Z"
          transform="translate(-253.5 -161.55081)"
          fill="#3f3d56"
        />
        <path
          d="M336.28141,365.52288H276.971a5.91,5.91,0,1,0,0,11.82005h59.31043a5.91,5.91,0,0,0,0-11.82005Z"
          transform="translate(-253.5 -161.55081)"
          fill="#3f3d56"
        />
        <polygon
          points="311.498 549.925 299.374 549.823 294.008 501.972 311.9 502.123 311.498 549.925"
          fill="#ffb6b6"
        />
        <path
          d="M526.16536,730.7539a5.42722,5.42722,0,0,0,5.3737,5.46759l24.10363.20114,4.21967-8.55322,1.54975,8.59689,9.09483.08249-2.29656-30.66186-3.16422-.21051-12.90631-.88967-4.16389-.27885-.073,8.6754-19.36243,13.133A5.41826,5.41826,0,0,0,526.16536,730.7539Z"
          transform="translate(-253.5 -161.55081)"
          fill="#2f2e41"
        />
        <polygon
          points="434.498 549.925 422.374 549.823 417.008 501.972 434.9 502.123 434.498 549.925"
          fill="#ffb6b6"
        />
        <path
          d="M649.16536,730.7539a5.42722,5.42722,0,0,0,5.3737,5.46759l24.10363.20114,4.21967-8.55322,1.54975,8.59689,9.09483.08249-2.29656-30.66186-3.16422-.21051-12.90631-.88967-4.16389-.27885-.073,8.6754-19.36243,13.133A5.41826,5.41826,0,0,0,649.16536,730.7539Z"
          transform="translate(-253.5 -161.55081)"
          fill="#2f2e41"
        />
        <path
          d="M550.04218,328.27133l54.56257-41.99115a11.113,11.113,0,0,0,2.08936-15.451l0,0a11.11305,11.11305,0,0,0-16.01957-1.86316l-55.33993,45.35608-34.78838,26.14255a9.69772,9.69772,0,1,0,4.238,11.70239Z"
          transform="translate(-253.5 -161.55081)"
          fill="#ffb6b6"
        />
        <path
          d="M594.51312,337.92434l-2.405,53.71423,81.68593,9.88431c-6.42764-21.58352-16.96811-51.56194-12.13817-66.58267Z"
          transform="translate(-253.5 -161.55081)"
          fill="#a0616a"
        />
        <path
          d="M640.94613,239.4513H615.78382l-10.80585,18.491L599.829,257.658c-10.035,3.86488-14.035,7.86488-19.57275,18.522v0l-19.46221,16.34289,15,18c10.3606-1.69748,14.09619-9.73972,10.8064-24.50989,0,0-11.346,19.77327,4.99761,29.50105,0,0-2.915,18.32813,0,18.13112s9.62931-3.92716,2.915-.197-5.56414,3.89107-5.34476,5.09967c3.62572,19.97506,6.62572,34.97506,6.62572,34.97506l71,7s-4.09121-42.93822-3.86866-45.37949-1.26951,3.527.22255-2.44127,3.73016-3.73015,1.49207-5.96825,15.38473-4.16554,8.507-14.9208l23-6-12.85623-30.55536c0-10.31684-16.56979-18.68029-26.88663-18.68029h-1.32157Z"
          transform="translate(-253.5 -161.55081)"
          fill="#e6e6e6"
        />
        <path
          d="M667.72255,372.4095c-27.77686,9.55472-52.78369,7.45679-73.92847-10.88662,0,0-5.95062,21.99987-11.97531,18.99987s-3.04714,5.39467-4.702,8.47239c-1.75706,3.26775.28282,8.77982-5.51992,10.65378s-3.623,7.38147-5.5015,11.43467c-13.14593,28.36453-28.15894,68.51467-38.30125,119.43929-10.422,52.329,15,140,15,140l24-2,6-114,27.4426-48.18613s13.48173-7.8498,5.01957-11.83183-2.79293-8.91669,3.37245-7.94936,13.16538-31.03268,13.16538-31.03268c-8.79053,22.09156,46,217,46,217l23-2-13-272Z"
          transform="translate(-253.5 -161.55081)"
          fill="#2f2e41"
        />
        <path
          d="M655.87921,193.02913c2.72768,6.79328,10.42287,20.15255,11.46517,27.44032l-.46913-.46134a32.90146,32.90146,0,0,1-3.0409,4.3478,21.133,21.133,0,0,0-7.17646-4.93446,25.29665,25.29665,0,0,1,2.51952,9.70681c-5.00953,4.3623-17.80333,1.73039-24.19538,4.05524-10.28669,3.73213-21.20775,5.06075-31.99106,6.16412-3.82745.394-8.112.63322-10.79413-1.62844s-1.08479-6.44993-2.52779-9.61778c-10.27859-22.56452-.79282-30.70565,7.26657-40.93726,1.77357-2.2516,1.76615-5.61169,3.92425-7.76,2.99438-2.97778,7.58443-3.8937,11.81627-4.62739a6.413,6.413,0,0,1-5.63344-2.78774,5.78522,5.78522,0,0,1,.07665-6.03836c1.51349-2.50916,4.65851-3.96827,7.69266-4.33278a29.21087,29.21087,0,0,1,8.90273.76529,15.93019,15.93019,0,0,1,5.39115,1.72317,4.4118,4.4118,0,0,1,2.29689,4.47155,5.71537,5.71537,0,0,1-3.37953,3.64257,14.96007,14.96007,0,0,1-3.82071,1.04591,29.97248,29.97248,0,0,1,17.58073,3.07719C648.56557,179.96138,653.14221,186.23947,655.87921,193.02913Z"
          transform="translate(-253.5 -161.55081)"
          fill="#2f2e41"
        />
        <circle cx="365.65488" cy="45.31999" r="24.24409" fill="#ffb6b6" />
        <path
          d="M642.48373,193.87152c-1.40575,4.24839-5.42275,5.61739-9.13571,6.4712-1.17071.27286-2.34865.52712-3.53742.75336-.43858-.54833-.90509-1.08578-1.39228-1.59373a21.133,21.133,0,0,0-7.17645-4.93444,24.90466,24.90466,0,0,1,2.30568,7.61189q-3.36681.48849-6.771.79841a7.65518,7.65518,0,0,1-8.99073-5.51644c-2.066,3.37852-4.50886,6.56027-7.84955,7.90235a9.49977,9.49977,0,0,1-10.80661-3.233c-2.00068-2.44082-3.115-5.71992-3.91647-8.8846-.94807-3.76219-1.47463-8.096.38677-10.483,1.49124-1.93184,4.06354-1.96653,6.43707-1.79514a76.60624,76.60624,0,0,1,15.46819,2.80431l.42716.96049c-.86726-5.20991,1.01385-10.22255,4.49433-12.00524,2.34761-1.19192,5.1721-.99206,7.91637-.75026a36.07777,36.07777,0,0,1,10.33745,1.99917,19.85675,19.85675,0,0,1,9.36924,7.483C642.4858,185.15834,643.69635,190.20257,642.48373,193.87152Z"
          transform="translate(-253.5 -161.55081)"
          fill="#2f2e41"
        />
        <path
          d="M607.92156,171.21174c.2392-.07062-.3806.0201-.16565.02586a1.97524,1.97524,0,0,1-.31728-.04442c-.01775-.00355-.2591-.08058-.09878-.024a1.94632,1.94632,0,0,1-.92482-.60354,8.063,8.063,0,0,1-1.32151-2.3521,8.44655,8.44655,0,0,0-1.81725-3.03,2.07886,2.07886,0,0,0-3.31693.5063c-.47188,1.04446-.04091,2.33135.29594,3.35718a10.4232,10.4232,0,0,0,1.53166,2.96979,9.532,9.532,0,0,0,4.38051,3.21392,9.9705,9.9705,0,0,0,6.26657.17907,1.5144,1.5144,0,0,0,.06046-2.771l-3.4252-1.40205a1.5,1.5,0,1,0-1.20824,2.746l3.4252,1.402.06046-2.771a5.96624,5.96624,0,0,1-1.31112.22419,5.02287,5.02287,0,0,1-1.42015-.05989,6.22675,6.22675,0,0,1-1.31407-.32044,8.56836,8.56836,0,0,1-.80012-.33913q-.14207-.069-.28159-.14326c.0067.00357-.39187-.2285-.217-.11946a8.45879,8.45879,0,0,1-1.24152-.96712,3.41477,3.41477,0,0,1-.37241-.41436,7.94024,7.94024,0,0,1-.54182-.75149,7.7918,7.7918,0,0,1-.79571-1.763,7.89851,7.89851,0,0,1-.22014-.79175,3.25067,3.25067,0,0,0-.07952-.43568c-.1271-.34132.02414.19931-.12208.3472l-.63138.46526a.82441.82441,0,0,1-.7057-.113q-.11218-.15789.04906.05239a4.89556,4.89556,0,0,1,.34278.5172,14.16063,14.16063,0,0,1,.6133,1.29953,14.26035,14.26035,0,0,0,1.42122,2.68412,4.82951,4.82951,0,0,0,5.29011,2.02276,1.51211,1.51211,0,0,0,.85434-1.94168A1.53069,1.53069,0,0,0,607.92156,171.21174Z"
          transform="translate(-253.5 -161.55081)"
          fill="#2f2e41"
        />
        <circle cx="316.60904" cy="201.38152" r="71.68503" fill="#fff" />
        <path
          d="M570.109,435.6176a72.68506,72.68506,0,1,1,72.68506-72.68554A72.76755,72.76755,0,0,1,570.109,435.6176Zm0-143.37011a70.68506,70.68506,0,1,0,70.68506,70.68457A70.76474,70.76474,0,0,0,570.109,292.24749Z"
          transform="translate(-253.5 -161.55081)"
          fill="#3f3d56"
        />
        <path
          d="M560.32972,400.241a7.97167,7.97167,0,0,1-6.37853-3.19024l-19.55677-26.07613a7.97378,7.97378,0,1,1,12.75836-9.56813l12.79471,17.05831,32.86156-49.29168a7.97412,7.97412,0,1,1,13.26974,8.84649L566.96524,396.69a7.97735,7.97735,0,0,1-6.41358,3.54846C560.47768,400.23975,560.4037,400.241,560.32972,400.241Z"
          transform="translate(-253.5 -161.55081)"
          fill="#70be10"
        />
        <path
          d="M491.14785,737.25919a1.18647,1.18647,0,0,0,1.19006,1.19h253.29a1.19,1.19,0,1,0,0-2.38h-253.29A1.1865,1.1865,0,0,0,491.14785,737.25919Z"
          transform="translate(-253.5 -161.55081)"
          fill="#ccc"
        />
        <path
          d="M715.41111,365.38421l-23.43325-64.73958a11.113,11.113,0,0,0-14.08624-6.684l0,0a11.11305,11.11305,0,0,0-6.64118,14.69669l26.403,66.50233,14.34006,41.08561a9.69773,9.69773,0,1,0,12.43677-.48307Z"
          transform="translate(-253.5 -161.55081)"
          fill="#ffb6b6"
        />
        <path
          d="M680.147,271.81307l19,34s-43.78731,14.99212-43.89365,12.49606,2.17565-37.13386,2.17565-37.13386Z"
          transform="translate(-253.5 -161.55081)"
          fill="#e6e6e6"
        />
      </svg>
    </Box>
  );
}

import useAuth from '../hooks/useAuth';
import createAvatar from '../utils/createAvatar';
import Avatar from './Avatar';
import { HOST_FILE_URL } from '../config';

export default function MyAvatar({ ...other }) {
  const { account } = useAuth();
  return (
    <Avatar
      src={account?.photo ? `${HOST_FILE_URL}/external/images/accounts/thumbnail/${account?.photo?.uri}` : null}
      alt={account?.personalDetails?.firstName}
      color={account?.photo?.uri ? 'default' : createAvatar(account?.personalDetails?.firstName).color}
      {...other}
    >
      {createAvatar(account?.personalDetails?.firstName).name}
    </Avatar>
  );
}
